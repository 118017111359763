var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { SizeAndPositionManager } from './size-and-position-manager';
import { InfinitelistService } from './infinite-list.service';
import { ILEvent } from './il-event';
import { Subject } from 'rxjs/Subject';
import { ALIGN_AUTO, DIRECTION_VERTICAL, SCROLL_CHANGE_OBSERVED, SCROLL_CHANGE_REQUESTED, positionProp, scrollProp, sizeProp } from './constants';
var InfinitelistComponent = (function () {
    function InfinitelistComponent(zone, infinitelistService) {
        this.zone = zone;
        this.infinitelistService = infinitelistService;
        this.styleCache = {};
        this._width = '100%';
        this._height = '100%';
        this.scrollDirection = DIRECTION_VERTICAL;
        this.scrollToAlignment = ALIGN_AUTO;
        this.useob = false;
        this.overscanCount = 4;
        this.debug = false;
        this.unit = 'px';
        this.update = new EventEmitter();
        this.ob$ = new Subject();
        this.items = [];
        this.event = new ILEvent();
        this.event.getStyle = this.getStyle.bind(this);
    }
    Object.defineProperty(InfinitelistComponent.prototype, "itemCount", {
        get: function () {
            return this.data ? this.data.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfinitelistComponent.prototype, "currentSizeProp", {
        get: function () {
            return sizeProp[this.scrollDirection];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfinitelistComponent.prototype, "currentScrollProp", {
        get: function () {
            return scrollProp[this.scrollDirection];
        },
        enumerable: true,
        configurable: true
    });
    InfinitelistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createSizeAndPositionManager();
        this.zone.runOutsideAngular(function () {
            _this.handleScrollbind = _this.handleScroll.bind(_this);
            _this.infinitelistService.addEventListener(_this.rootNode.nativeElement, 'scroll', _this.handleScrollbind);
        });
        // set offset init value
        this.offset = this.scrollOffset || this.scrollToIndex != null && this.getOffsetForIndex(this.scrollToIndex) || 0;
        this.scrollChangeReason = SCROLL_CHANGE_REQUESTED;
        // srcoll init value
        if (this.scrollOffset != null) {
            this.scrollTo(this.scrollOffset);
        }
        else if (this.scrollToIndex != null) {
            this.scrollTo(this.getOffsetForIndex(this.scrollToIndex));
        }
        if (this.useob) {
            setTimeout(function () {
                _this.update.emit(_this.ob$);
                _this.ngRender();
            }, 0);
        }
        else {
            this.ngRender();
        }
    };
    InfinitelistComponent.prototype.ngOnDestroy = function () {
        this.sizeAndPositionManager.destroy();
        this.infinitelistService.removeEventListener(this.rootNode.nativeElement, 'scroll', this.handleScrollbind);
    };
    InfinitelistComponent.prototype.ngOnChanges = function (changes) {
        this.createSizeAndPositionManager();
        var scrollPropsHaveChanged = (this.valueChanged(changes, 'scrollToIndex') ||
            this.valueChanged(changes, 'scrollToAlignment'));
        var itemPropsHaveChanged = (this.valueChanged(changes, 'data') ||
            this.valueChanged(changes, 'itemSize') ||
            this.valueChanged(changes, 'estimatedItemSize'));
        if (this.valueChanged(changes, 'data') ||
            this.valueChanged(changes, 'estimatedItemSize')) {
            this.sizeAndPositionManager.updateConfig({
                itemCount: this.itemCount,
                estimatedItemSize: this.getEstimatedItemSize(),
            });
        }
        if (itemPropsHaveChanged)
            this.recomputeSizes();
        this.warpStyle = __assign({}, STYLE_WRAPPER, { height: this.addUnit(this.height), width: this.addUnit(this.width) });
        this.innerStyle = __assign({}, STYLE_INNER, (_a = {}, _a[this.currentSizeProp] = this.addUnit(this.sizeAndPositionManager.getTotalSize()), _a));
        if (this.valueChanged(changes, 'scrollOffset')) {
            this.offset = this.scrollOffset || 0;
            this.scrollChangeReason = SCROLL_CHANGE_REQUESTED;
            this.ngRender();
        }
        else if (typeof this.scrollToIndex === 'number' && (scrollPropsHaveChanged || itemPropsHaveChanged)) {
            this.offset = this.getOffsetForIndex(this.scrollToIndex, this.scrollToAlignment, this.itemCount);
            this.scrollChangeReason = SCROLL_CHANGE_REQUESTED;
            this.ngRender();
        }
        var _a;
    };
    InfinitelistComponent.prototype.ngDidUpdate = function () {
        if (this.oldOffset !== this.offset && this.scrollChangeReason === SCROLL_CHANGE_REQUESTED) {
            this.scrollTo(this.offset);
        }
    };
    InfinitelistComponent.prototype.ngRender = function () {
        var _this = this;
        var _a = this.sizeAndPositionManager.getVisibleRange({
            containerSize: this[this.currentSizeProp] || 0,
            offset: this.offset,
            overscanCount: this.overscanCount
        }), start = _a.start, stop = _a.stop;
        // fill items;
        if (typeof start !== 'undefined' && typeof stop !== 'undefined') {
            this.items.length = 0;
            for (var i = start; i <= stop; i++) {
                this.items.push(this.data[i]);
            }
            this.event.start = start;
            this.event.stop = stop;
            this.event.offset = this.offset;
            this.event.items = this.items;
            if (!this.infinitelistService.isPureNumber(this.itemSize))
                this.innerStyle = __assign({}, STYLE_INNER, (_b = {}, _b[this.currentSizeProp] = this.addUnit(this.sizeAndPositionManager.getTotalSize()), _b));
            if (this.useob) {
                this.ob$.next(this.event);
                if (!this.infinitelistService.isPureNumber(this.itemSize))
                    this.innerNode.nativeElement.style[this.currentSizeProp] = this.addUnit(this.sizeAndPositionManager.getTotalSize());
            }
            else {
                this.zone.run(function () { return _this.update.emit(_this.event); });
            }
        }
        this.ngDidUpdate();
        var _b;
    };
    InfinitelistComponent.prototype.valueChanged = function (changes, key) {
        return changes[key] ? changes[key].currentValue !== changes[key].previousValue : false;
    };
    InfinitelistComponent.prototype.handleScroll = function (e) {
        var offset = this.getNodeOffset();
        if (offset < 0 || this.offset === offset || e.target !== this.rootNode.nativeElement)
            return;
        this.offset = offset;
        this.scrollChangeReason = SCROLL_CHANGE_OBSERVED;
        this.ngRender();
    };
    InfinitelistComponent.prototype.getStyle = function (index) {
        index += this.event.start;
        var style = this.styleCache[index];
        if (style)
            return style;
        var _a = this.sizeAndPositionManager.getSizeAndPositionForIndex(index), size = _a.size, offset = _a.offset;
        var debugStyle = this.debug ? { backgroundColor: this.infinitelistService.randomColor() } : null;
        return this.styleCache[index] = __assign({}, STYLE_ITEM, debugStyle, (_b = {}, _b[this.currentSizeProp] = this.addUnit(size), _b[positionProp[this.scrollDirection]] = this.addUnit(offset), _b));
        var _b;
    };
    ////////////////////////////////////////////////////////////////////////////
    // PRIVATE
    ////////////////////////////////////////////////////////////////////////////
    // init SizeAndPositionManager
    InfinitelistComponent.prototype.createSizeAndPositionManager = function () {
        var _this = this;
        if (!this.sizeAndPositionManager)
            this.sizeAndPositionManager = new SizeAndPositionManager({
                itemCount: this.itemCount,
                itemSizeGetter: function (index) { return _this.getSize(index); },
                estimatedItemSize: this.getEstimatedItemSize(),
            });
        return this.sizeAndPositionManager;
    };
    InfinitelistComponent.prototype.addUnit = function (val) {
        return typeof val === 'string' ? val : val + this.unit;
    };
    InfinitelistComponent.prototype.getEstimatedItemSize = function () {
        return this.estimatedItemSize || typeof this.itemSize === 'number' && this.itemSize || 50;
    };
    InfinitelistComponent.prototype.getNodeOffset = function () {
        return this.rootNode.nativeElement[this.currentScrollProp];
    };
    InfinitelistComponent.prototype.scrollTo = function (value) {
        this.rootNode.nativeElement[this.currentScrollProp] = value;
        this.oldOffset = value;
    };
    InfinitelistComponent.prototype.getOffsetForIndex = function (index, scrollToAlignment, itemCount) {
        if (scrollToAlignment === void 0) { scrollToAlignment = this.scrollToAlignment; }
        if (itemCount === void 0) { itemCount = this.itemCount; }
        if (index < 0 || index >= itemCount)
            index = 0;
        return this.sizeAndPositionManager.getUpdatedOffsetForIndex({
            align: this.scrollToAlignment,
            containerSize: this[this.currentSizeProp],
            currentOffset: this.offset || 0,
            targetIndex: index,
        });
    };
    InfinitelistComponent.prototype.getSize = function (index) {
        if (typeof this.itemSize === 'function') {
            return this.itemSize(index);
        }
        return this.infinitelistService.isArray(this.itemSize) ? this.itemSize[index] : this.itemSize;
    };
    InfinitelistComponent.prototype.recomputeSizes = function (startIndex) {
        if (startIndex === void 0) { startIndex = 0; }
        this.styleCache = {};
        this.sizeAndPositionManager.resetItem(startIndex);
    };
    InfinitelistComponent.decorators = [
        { type: Component, args: [{
                    selector: 'infinite-list, infinitelist, [infinitelist]',
                    template: "\n<div #dom [ngStyle]=\"warpStyle\">\n  <div #inner [ngStyle]=\"innerStyle\">\n    <ng-content></ng-content>\n  </div>\n</div>\n  ",
                    changeDetection: ChangeDetectionStrategy.OnPush
                },] },
    ];
    /** @nocollapse */
    InfinitelistComponent.ctorParameters = function () { return [
        { type: NgZone, },
        { type: InfinitelistService, },
    ]; };
    InfinitelistComponent.propDecorators = {
        'scrollDirection': [{ type: Input },],
        'scrollToAlignment': [{ type: Input },],
        'useob': [{ type: Input },],
        'overscanCount': [{ type: Input },],
        'itemSize': [{ type: Input },],
        'data': [{ type: Input },],
        'debug': [{ type: Input },],
        'unit': [{ type: Input },],
        'width': [{ type: Input },],
        'height': [{ type: Input },],
        'scrollOffset': [{ type: Input },],
        'scrollToIndex': [{ type: Input },],
        'estimatedItemSize': [{ type: Input },],
        'update': [{ type: Output },],
        'rootNode': [{ type: ViewChild, args: ['dom', { read: ElementRef },] },],
        'innerNode': [{ type: ViewChild, args: ['inner', { read: ElementRef },] },],
    };
    return InfinitelistComponent;
}());
export { InfinitelistComponent };
var STYLE_WRAPPER = {
    overflow: 'auto',
    willChange: 'transform',
    WebkitOverflowScrolling: 'touch',
};
var STYLE_INNER = {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    minHeight: '100%',
};
var STYLE_ITEM = {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%'
};
