import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfinitelistComponent } from './infinite-list.component';
import { InfinitelistService } from './infinite-list.service';
var InfiniteListModule = (function () {
    function InfiniteListModule() {
    }
    InfiniteListModule.decorators = [
        { type: NgModule, args: [{
                    declarations: [InfinitelistComponent],
                    exports: [InfinitelistComponent],
                    imports: [CommonModule],
                    providers: [InfinitelistService]
                },] },
    ];
    /** @nocollapse */
    InfiniteListModule.ctorParameters = function () { return []; };
    return InfiniteListModule;
}());
export { InfiniteListModule };
