import { Injectable } from '@angular/core';
var InfinitelistService = (function () {
    function InfinitelistService() {
    }
    InfinitelistService.prototype.addEventListener = function (ele, eventType, callback) {
        if (ele.addEventListener) {
            return ele.addEventListener(eventType, callback, false);
        }
        else if (ele['attachEvent']) {
            return ele['attachEvent'](eventType, callback);
        }
        else {
            return ele["on" + eventType] = callback;
        }
    };
    InfinitelistService.prototype.removeEventListener = function (ele, eventType, callback) {
        if (ele.removeEventListener) {
            return ele.removeEventListener(eventType, callback, false);
        }
        else if (ele['detachEvent']) {
            return ele['detachEvent'](eventType, callback);
        }
        else {
            return ele["on" + eventType] = null;
        }
    };
    InfinitelistService.prototype.isArray = function (val) {
        return Object.prototype.toString.call(val) === '[object Array]';
    };
    InfinitelistService.prototype.randomColor = function () {
        return '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).slice(-6);
    };
    InfinitelistService.prototype.isPureNumber = function (val) {
        if (typeof val === 'number' || !val)
            return true;
        else
            return false;
    };
    InfinitelistService.decorators = [
        { type: Injectable },
    ];
    /** @nocollapse */
    InfinitelistService.ctorParameters = function () { return []; };
    return InfinitelistService;
}());
export { InfinitelistService };
